import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvertisingComponent } from './advertising.component';
import { SharedTranslocoModule } from '@schaeffler/transloco';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [AdvertisingComponent],
  imports: [
    CommonModule,
    SharedTranslocoModule,
    MatDialogModule,
    MatButtonModule,
  ],
})
export class AdvertisingModule {}
