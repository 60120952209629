import { defaultExternalLinks } from './environment.defaults';
import { Environment } from './environment.model';

export const environment: Environment = {
  production: false,
  localDev: false,
  baseUrl: '/landing/app/',
  apiUrl: 'https://bearinx-d.schaeffler.com/landing/api/',
  externalLinks: {
    ...defaultExternalLinks,
    rotative: 'https://caeonline-d.schaeffler.com/bearinx-spa',
    easyPlanet: 'https://bearinx-d.schaeffler.com/easy-planet/app',
  },
};
