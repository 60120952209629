import { AfterViewInit, Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { AppShellFooterLink } from '@schaeffler/app-shell/lib/models/footer-link.model';
import { AVAILABLE_LANGUAGES } from './models/language-constants';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'schaeffler-landing-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  public footerLinks: AppShellFooterLink[] = this.translateFooter();
  public version: string = require('../../../../package.json').version;
  public languages: string[] = AVAILABLE_LANGUAGES.map((entry) =>
    entry.id.toUpperCase()
  );
  public selectedLanguage = this.translocoService.getActiveLang().toUpperCase();

  constructor(private readonly translocoService: TranslocoService) {}

  ngAfterViewInit(): void {
    this.translocoService.langChanges$.subscribe(() => {
      // we need a small delay, otherwise the footer is not updating
      setTimeout(() => {
        this.footerLinks = this.translateFooter();
      }, 10);
    });
  }

  public onLanguageChange(newLanguage: MatSelectChange) {
    this.translocoService.setActiveLang(
      (newLanguage.value as string).toLowerCase()
    );
  }

  private translateFooter(): AppShellFooterLink[] {
    return [
      {
        link: '/landing',
        title: this.translocoService.translate('menu.home'),
        external: false,
      },
      {
        link: '/about',
        title: this.translocoService.translate('menu.about'),
        external: false,
      },
      {
        link: '/legal-guidelines',
        title: this.translocoService.translate('menu.legal_guidelines'),
        external: false,
      },
      {
        link: '/privacy-policy',
        title: this.translocoService.translate('menu.privacy_policy'),
        external: false,
      },
      {
        link: '/terms-of-use',
        title: this.translocoService.translate('menu.conditions_of_use'),
        external: false,
      },
      {
        link: '/support',
        title: this.translocoService.translate('menu.support'),
        external: false,
      },
      {
        link: '/imprint',
        title: this.translocoService.translate('menu.imprint'),
        external: false,
      },
    ];
  }
}
