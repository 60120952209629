<schaeffler-app-shell
  #appShell
  appTitle="{{ 'menu.title' | transloco }}"
  [hasSidebarLeft]="false"
  [appTitleLink]="'/landing'"
  [hasFooter]="true"
  [footerLinks]="footerLinks"
  [appVersion]="version"
>
  <ng-container mainContent>
    <div class="fullscreen-bg h-full w-full">
      <router-outlet></router-outlet>
    </div>
  </ng-container>
  <ng-container headerInlineContent>
    <mat-select
      class="select-language"
      [(value)]="selectedLanguage"
      (selectionChange)="onLanguageChange($event)"
    >
      <mat-option [value]="option" *ngFor="let option of languages">{{
        option
      }}</mat-option>
    </mat-select>
  </ng-container>
</schaeffler-app-shell>
