import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: `/landing`,
    pathMatch: 'full',
  },
  {
    path: 'landing',
    loadChildren: () =>
      import('./pages/landing/landing.module').then((m) => m.LandingModule),
  },
  {
    path: 'imprint',
    loadChildren: () =>
      import('./pages/imprint/imprint.module').then((m) => m.ImprintModule),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./pages/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule
      ),
  },
  {
    path: 'terms-of-use',
    loadChildren: () =>
      import('./pages/terms-of-use/terms-of-use.module').then(
        (m) => m.TermsOfUseModule
      ),
  },
  {
    path: 'support',
    loadChildren: () =>
      import('./pages/support/support.module').then((m) => m.SupportModule),
  },
  {
    path: 'legal-guidelines',
    loadChildren: () =>
      import('./pages/legal-guidelines/legal-guidelines.module').then(
        (m) => m.LegalGuidelinesModule
      ),
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./pages/about/about.module').then((m) => m.AboutModule),
  },
  {
    path: 'registration',
    loadChildren: () =>
      import('./pages/registration-form/registration-form.module').then(
        (m) => m.RegistrationFormModule
      ),
  },
  {
    path: 'forbidden',
    loadChildren: () =>
      import('@schaeffler/empty-states').then((m) => m.ForbiddenModule),
  },
  {
    path: 'page-not-found',
    loadChildren: () =>
      import('@schaeffler/empty-states').then((m) => m.PageNotFoundModule),
  },
  {
    path: '**',
    redirectTo: `/page-not-found`,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
