import { ExternalLinks } from './environment.model';

export const defaultExternalLinks: ExternalLinks = {
  easyCalc: 'https://medias-easycalc.com/',
  medias: 'https://medias.schaeffler.com/',
  schaeffler: 'https://www.schaeffler.com/',
  grease: 'https://greaseapp.com/app',
  mounting: 'https://mountingmanager.com/app',
  rotative: '',
  easyPlanet: '',
};
