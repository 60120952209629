import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppShellModule } from '@schaeffler/app-shell';
import { SharedTranslocoModule } from '@schaeffler/transloco';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';

import { TranslocoModule } from '@ngneat/transloco';

import { PushPipe } from '@ngrx/component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppComponent } from './app.component';
import { MatDividerModule } from '@angular/material/divider';
import {
  AVAILABLE_LANGUAGES,
  FALLBACK_LANGUAGE,
} from './models/language-constants';
import { AdvertisingModule } from './pages/advertising/advertising.module';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,

    AppRoutingModule,

    TranslocoModule,
    // Translation
    SharedTranslocoModule.forRoot(
      environment.production,
      AVAILABLE_LANGUAGES,
      '', // default -> undefined would lead to browser detection
      FALLBACK_LANGUAGE.id,
      'language', // language storage key -> language is not persisted in this app
      true,
      false,
      undefined,
      `${environment.baseUrl}assets/i18n/`
    ),

    // UI Modules
    AppShellModule,

    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    PushPipe,
    MatDividerModule,
    AdvertisingModule,
    MatSelectModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
